import find from 'lodash/find';
import reduce from 'lodash/reduce';

import {
  dollarStringToNumber,
  maybeNullDollarString,
  numberToDollarString,
  trimEmptyCents,
} from 'helpers/money';
import { displayPrice } from 'helpers/servings';
import {
  NOT_ENROLLED_BETA_BASKET_STATUS,
  ENROLLED_BETA_BASKET_STATUS,
  WAITLISTED_BETA_BASKET_STATUS,
  PROCESSED_BETA_BASKET_STATUS,
  SHIPPED_BETA_BASKET_STATUS,
  DELIVERED_BETA_BASKET_STATUS,
} from 'utilities/types';

const transformMeal = meal => {
  const displayPriceCents = displayPrice({
    price: meal.basePriceCents,
    displayUnit: meal.displayUnit,
  });

  return {
    ...meal,
    basePriceFormatted: numberToDollarString(meal.basePriceCents / 100),
    basePriceDollars: meal.basePriceCents / 100,
    priceFormatted: meal.price,
    displayPriceCents,
    displayPrice: numberToDollarString(displayPriceCents / 100),
    strikethroughPriceCents: meal.pricePromotion
      ? meal.pricePromotion.strikethroughPriceCents
      : meal.strikethroughPriceCents,
    totalPriceWithUpcharge: meal.totalPriceWithUpcharge / 100,
    totalPriceWithoutUpcharge: meal.totalPriceWithoutUpcharge / 100,
    priceDollars: meal.price ? dollarStringToNumber(meal.price) : 0,
    prepMinutesFormatted: meal.prepMinutes + ' min',
    daysCookByFormatted: meal.daysCookBy + (meal.daysCookBy > 1 ? ' days' : ' day'),
    cookByRange:
      meal.cookByRangeMin && meal.cookByRangeMax
        ? transformCookByRange(meal.cookByRangeMin, meal.cookByRangeMax)
        : null,
    instructions: meal.instructions ? meal.instructions.map(transformInstruction) : '',
    showCookingGuidelines: !['Snappy Snack', 'Smoothie'].includes(meal.mealAddonName),
  };
};

const transformCookByRange = (cookByRangeMin, cookByRangeMax) => {
  return `${cookByRangeMin} to ${cookByRangeMax}`;
};

const transformInstruction = instruction => ({
  ...instruction,
  descriptionMarkdown: instruction.descriptionMarkdown.replace(/\r?\n|\r| \r?\n/g, '\n\n'),
});

export const transformBasketResponse = response => {
  const basket = response.weeklyBasket;

  return {
    ...response,
    weeklyBasket: {
      ...basket,
      recurringDiscountFormatted: numberToDollarString(basket.recurringDiscountCents / 100),
      subtotalFormatted: maybeNullDollarString(basket.subtotal),
      subtotalDollars: dollarStringToNumber(maybeNullDollarString(basket.subtotal)),
      shippingFormatted: maybeNullDollarString(basket.shipping),
      shippingDollars: dollarStringToNumber(maybeNullDollarString(basket.shipping)),
      freeShipping: basket.freeShipping,
      strikethroughShippingPriceFormatted: numberToDollarString(
        basket.strikethroughShippingCents / 100
      ),
      salesTaxFormatted: maybeNullDollarString(basket.salesTax),
      meals: basket.meals.map(transformMeal),
      totalFormatted: maybeNullDollarString(basket.total),
      totalDollars: dollarStringToNumber(maybeNullDollarString(basket.total)),
      creditFormatted: maybeNullDollarString(basket.credit),
      creditDollars: dollarStringToNumber(maybeNullDollarString(basket.credit)),
      shippingPriceDiffAmountCentsFormatted: trimEmptyCents(
        numberToDollarString(basket.shippingPriceDiffAmountCents / 100)
      ),
    },
  };
};

export const transformSkeletonBasketResponse = response => {
  const basket = response.weeklyBasket;

  return {
    ...response,
    weeklyBasket: {
      ...basket,
      subtotalFormatted: maybeNullDollarString(basket.subtotal),
      subtotalDollars: dollarStringToNumber(maybeNullDollarString(basket.subtotal)),
      freeShipping: basket.freeShipping,
      strikethroughShippingPriceFormatted: numberToDollarString(
        basket.strikethroughShippingCents / 100
      ),
      meals: basket.meals.map(transformMeal),
      totalFormatted: maybeNullDollarString(basket.total),
      totalDollars: dollarStringToNumber(maybeNullDollarString(basket.total)),
      shippingPriceDiffAmountCentsFormatted: trimEmptyCents(
        numberToDollarString(basket.shippingPriceDiffAmountCents / 100)
      ),
    },
  };
};

const transformMealOption = (mealOption, meal) => ({
  ...mealOption,
  displayPriceModifierCents: displayPrice({
    price: mealOption.priceModifierCents,
    displayUnit: meal.displayUnit,
  }),
});

const transformMealOptionGroup = (mealOptionGroup, meal) => ({
  ...mealOptionGroup,
  mealOptions: mealOptionGroup.mealOptions.map(option => transformMealOption(option, meal)),
});

export const transformMenuResponse = response => ({
  menu: {
    ...response,
    meals: response.meals.map(transformMeal),
    mealOptionGroups: response.mealOptionGroups.map(group =>
      transformMealOptionGroup(
        group,
        response.meals.find(meal => meal.id === group.mealId)
      )
    ),
  },
});

export const transformMenuMealResponse = response => {
  return {
    meal: transformMeal(response.meal),
  };
};

export const transformNsoMenuResponse = response => ({
  menu: {
    ...response,
    meals: response.meals.map(transformMeal),
  },
});

export const transformNsoBoxResponse = response => {
  return {
    ...response,
    box: {
      ...response.box,
      meals: response.box?.meals?.map(transformMeal),
    },
  };
};

export const transformNsoBoxWeeklyBasket = response => {
  return {
    ...response,
    boxWeeklyBasket: {
      ...response.boxWeeklyBasket,
      box: {
        ...response.boxWeeklyBasket.box,
        meals: response.boxWeeklyBasket?.box?.meals?.map(transformMeal),
      },
    },
  };
};

export const transformMealsResponse = response => ({
  meals: response.meals.map(meal => transformMeal(meal)),
});

export const transformMealResponse = response => ({
  meal: transformMeal(response),
});

const transformBetaBasketStatus = status => {
  switch (status) {
    case 'opted_in':
      return ENROLLED_BETA_BASKET_STATUS;
    case 'opted_out':
      return NOT_ENROLLED_BETA_BASKET_STATUS;
    case 'pending':
      return NOT_ENROLLED_BETA_BASKET_STATUS;
    case 'waitlisted':
      return WAITLISTED_BETA_BASKET_STATUS;
    case 'processed':
      return PROCESSED_BETA_BASKET_STATUS;
    case 'shipped':
      return SHIPPED_BETA_BASKET_STATUS;
    case 'delivered':
      return DELIVERED_BETA_BASKET_STATUS;
  }
};

export const transformBetaBasketResponse = betaBasket => ({
  ...betaBasket,
  status: transformBetaBasketStatus(betaBasket.status),
  meals: betaBasket.meals.map(transformMeal),
});

export const transformBetaBasketsResponse = response => {
  return {
    betaBaskets: response.betaBaskets.map(betaBasket => ({
      ...betaBasket,
      status: transformBetaBasketStatus(betaBasket.status),
      meals: betaBasket.meals.map(transformMeal),
    })),
  };
};

export const transformReceiptResponse = response => {
  const creditAndDiscountDollars = (response.creditCents + response.discountCents) / 100;
  return {
    ...response,
    belowUpchargeableThreshold: response.belowUpchargeableThreshold,
    creditDollars: Number(response.creditCents) / 100,
    creditAndDiscountDollars,
    creditAndDiscountFormatted: numberToDollarString(creditAndDiscountDollars),
    discountDollars: Number(response.discountCents) / 100,
    orderSummaryMeals: response.lineItems.meals,
    recurringDiscountFormatted: numberToDollarString(response.recurringDiscountCents / 100),
    subtotalFormatted: numberToDollarString(response.subtotal),
    subtotalDollars: response.subtotal,
    shippingFormatted: numberToDollarString(response.shippingCost),
    shippingDollars: response.shippingCost,
    salesTaxFormatted: numberToDollarString(response.salesTax),
    totalDollars: response.total,
    totalFormatted: numberToDollarString(response.total),
  };
};

export const transformShoppingListMealResponse = response => ({
  shoppingListMeal: {
    ...response.shoppingListMeal,
    ingredients: response.shoppingListMeal.ingredients.map(ingredient => ({
      ...ingredient,
      quantity: Number(ingredient.quantity),
    })),
  },
});

export const transformFavoritesResponse = response => ({
  favorites: response.favorites.reduce(
    (obj, favorite) => ({
      ...obj,
      [favorite.mealConcept]: favorite.id,
    }),
    {}
  ),
});

export const transformFavoriteResponse = response => ({
  favorite: {
    [response.favorite.mealConcept]: response.favorite.id,
  },
});

export const transformMealOptionSelections = (mealOptionSelections, meals) => {
  return reduce(
    mealOptionSelections,
    (transformedMealOptions, mealOptions, mealId) => {
      const meal = find(meals, { id: mealId });
      transformedMealOptions[mealId] = meal
        ? mealOptions.map(option => transformMealOption(option, meal))
        : [];
      return transformedMealOptions;
    },
    {}
  );
};

export const transformPastOrdersResponse = response => {
  return {
    ...response,
    weeklyBaskets: response.weeklyBaskets.map(basket => ({
      id: basket.id,
      total: basket.total,
      menuId: basket.menuId,
      chargedDate: basket.chargedDate,
      deliveryDate: basket.deliveryDate,
      status: basket.skipped ? 'Cancelled' : basket.status,
    })),
  };
};

export const transformUserResponse = response => ({
  user: {
    ...response.user,
    ...transformFavoritesResponse(response.user),
  },
});

export const transformMenuSearchMeals = response => ({
  ...response,
  orderableMeals: response.meals
    .filter(meal => meal.orderable)
    .sort((a, b) => {
      return Date.parse(a.currentOrderableMenuDate) - Date.parse(b.currentOrderableMenuDate);
    }),
  pastMeals: response.meals.filter(meal => !meal.orderable),
});

export const transformMealForBottomSheet = meal => {
  return transformMeal(meal);
};

export const transformProjectedCosts = response => ({
  weeklyBasket: {
    ...response.weeklyBasket,
    pricePerServingFormatted: numberToDollarString(response.weeklyBasket.pricePerServing / 100),
    subtotalFormatted: numberToDollarString(response.weeklyBasket.subtotal / 100),
    shippingCostFormatted: numberToDollarString(response.weeklyBasket.shippingCost / 100),
  },
});
