import CalorieConscious from 'components/icons/CalorieConscious';
import CarbConscious from 'components/icons/CarbConscious';
import EasyPrep from 'components/icons/EasyPrep';
import FiberRich from 'components/icons/FiberRich';
import GlutenSmart from 'components/icons/GlutenSmart';
import KetoFriendly from 'components/icons/KetoFriendly';
import Mediterranean from 'components/icons/Mediterranean';
import PaleoFriendly from 'components/icons/PaleoFriendly';
import Pescatarian from 'components/icons/Pescatarian';
import ProteinPacked from 'components/icons/ProteinPacked';
import Vegetarian from 'components/icons/Vegetarian';

export * from './analytics';
export * from './routingConstants';
export * from './experimentConstants';
export * from './featureFlags';
export * from './timeFormats';
export * from './signUp';
export * from './menuCategories';
export * from './sendABox';
export * from './payments';
export * from './nutritionFacts';
export * from './mealFilters';

// Meal Plan Slugs
export const STANDARD = 'standard';
export const FAMILY = 'family';
export const CORE = 'core';
export const TEMPO = 'tempo';

//Meal Plan Local Storage
export const FAMILY_PLAN = 'familyPlan';

// Basket Page Categories
export const MORE_OPTIONS = 'Other Available Meals';

// Addon used in MealType / Quick Filter:
export const DESSERT = 'Dessert';

// Dietary Preferences
export const VEGETARIAN = 'Vegetarian';
export const CALORIE_CONSCIOUS = 'Calorie-Conscious';
export const CARB_CONSCIOUS = 'Carb-Conscious';
export const KETO_FRIENDLY = 'Keto-Friendly';
export const PALEO_FRIENDLY = 'Paleo-Friendly';
export const HIGH_PROTEIN = 'Protein-Packed';
export const GLUTEN_SMART = 'Gluten-Smart';
export const FIBER_RICH = 'Fiber-Rich';
export const PESCATARIAN = 'Pescatarian';
export const EASY_PREP = 'Easy-Prep';
export const MEDITERRANEAN = 'Mediterranean';
// eslint-disable-next-line quotes
export const EAT_ANYTHING = "I'll Eat Anything";

// Opt-ins
export const KETO = 'Keto';
export const OVEN_READY_PLUS = 'Oven Ready Plus';
export const EXPRESS_PLUS = 'Express Plus';

// Dietary Preferences used in meal details slideouts
export const DIETARY_PREFERENCE_TAGS = [
  CALORIE_CONSCIOUS,
  CARB_CONSCIOUS,
  VEGETARIAN,
  KETO_FRIENDLY,
  HIGH_PROTEIN,
  GLUTEN_SMART,
  FIBER_RICH,
  PESCATARIAN,
  MEDITERRANEAN,
  PALEO_FRIENDLY,
];

// Spice Levels
export const NOT_SPICY = 'Not Spicy';
export const MILD = 'Mild';
export const MEDIUM = 'Medium';
export const SPICY = 'Spicy';

// Servings
export const STANDARD_MEAL_SERVING_SIZE = 2;
export const FAMILY_PLAN_SERVINGS_MULTIPLIER = 2;
export const MIN_SERVINGS = 2;

export const tasteProfileIconMapping = {
  'Low Calorie': CalorieConscious,
  Vegetarian: Vegetarian,
  'Low Carb': CarbConscious,
  Keto: KetoFriendly,
  'Gluten Smart': GlutenSmart,
  'Easy Prep': EasyPrep,
  'High Protein': ProteinPacked,
  'Fiber Rich': FiberRich,
  Pescatarian: Pescatarian,
  Mediterranean: Mediterranean,
};

export const iconTags = (isTempo = false) => ({
  [CALORIE_CONSCIOUS]: {
    icon: CalorieConscious,
    tooltip: isTempo ? 'Under 600 calories' : 'Under 625 calories',
  },
  [CARB_CONSCIOUS]: { icon: CarbConscious, tooltip: 'Under 35g carbs' },
  [VEGETARIAN]: { icon: Vegetarian, tooltip: 'Vegetarian' },
  [HIGH_PROTEIN]: { icon: ProteinPacked, tooltip: 'Over 30g protein' },
  [FIBER_RICH]: { icon: FiberRich, tooltip: 'Fiber Rich' },
  [PESCATARIAN]: { icon: Pescatarian, tooltip: 'Pescatarian' },
  [KETO_FRIENDLY]: { icon: KetoFriendly },
  [PALEO_FRIENDLY]: { icon: PaleoFriendly },
  [GLUTEN_SMART]: { icon: GlutenSmart },
  [EASY_PREP]: { icon: EasyPrep },
  [MEDITERRANEAN]: { icon: Mediterranean },
});

export const textTags = {
  [CALORIE_CONSCIOUS]: { text: '< 600 Cals', color: 'bg-charcoal-50' },
  [CARB_CONSCIOUS]: { text: '< 35g Carbs', color: 'bg-charcoal-50' },
  [VEGETARIAN]: { text: 'Vegetarian', color: 'bg-vegetarian-100' },
  [HIGH_PROTEIN]: { text: '30g+ Protein', color: 'bg-charcoal-50' },
  [FIBER_RICH]: { text: '20% DV Fiber', color: 'bg-charcoal-50' },
  [PESCATARIAN]: { text: 'Pescatarian', color: 'bg-pescatarian-100' },
  [KETO_FRIENDLY]: { text: 'Keto-Friendly', color: 'bg-keto-100' },
  [PALEO_FRIENDLY]: { text: 'Paleo-Friendly', color: 'bg-paleo-100' },
  [GLUTEN_SMART]: { text: 'Gluten-Smart', color: 'bg-gluten-100' },
  [MEDITERRANEAN]: { text: 'Mediterranean', color: 'bg-med-100' },
};

export const labelTags = {
  [KETO_FRIENDLY]: { color: 'bg-keto-100', text: KETO_FRIENDLY },
  [GLUTEN_SMART]: { color: 'bg-gluten-100', text: GLUTEN_SMART },
  [MEDITERRANEAN]: { color: 'bg-med-100', text: MEDITERRANEAN },
  [PALEO_FRIENDLY]: { color: 'bg-paleo-100', text: PALEO_FRIENDLY },
};

export const DIETARY_PREFERENCE_QUICK_FILTER_MAP = {
  [VEGETARIAN.toLowerCase()]: 'Vegetarian',
  [PALEO_FRIENDLY.toLowerCase()]: 'Paleo',
  [KETO_FRIENDLY.toLowerCase()]: 'Keto',
  [GLUTEN_SMART.toLowerCase()]: 'Gluten Smart',
};

// Displayed filter options
export const DISPLAYED_DIETARY_PREFERENCES = [
  CARB_CONSCIOUS,
  CALORIE_CONSCIOUS,
  VEGETARIAN,
  HIGH_PROTEIN,
  FIBER_RICH,
  GLUTEN_SMART,
  KETO_FRIENDLY,
  PESCATARIAN,
  EASY_PREP,
  MEDITERRANEAN,
  EAT_ANYTHING,
  PALEO_FRIENDLY,
];

export const DISPLAYED_TEMPO_DIETARY_PREFERENCES = [
  CARB_CONSCIOUS,
  CALORIE_CONSCIOUS,
  VEGETARIAN,
  PESCATARIAN,
  FIBER_RICH,
  HIGH_PROTEIN,
];

export const DISPLAYED_AVOIDANCES = [
  'beef',
  'fish',
  'milk',
  'mushrooms',
  'peanuts',
  'pork',
  'poultry',
  'sesame',
  'shellfish',
  'soy',
  'tree nuts',
  'wheat',
];

// Pause reasons
export const PAUSE_REASON_QUICK_CANCEL = 'reason_quick_cancel';

// Menu search popular terms
export const POPULAR_SEARCHES = {
  [STANDARD]: ['Chicken', 'Steak Strips', 'Rice Bowl', 'Tacos'],
  [FAMILY]: ['One-Pan Meals', 'Tacos', 'Chicken', 'Beef'],
  [TEMPO]: ['Pasta', 'Meatballs', 'Salmon', 'Chicken'],
};

export const SUBSCRIPTION_FREQUENCY_OPTIONS = {
  weekly: 'Subscriptions::Frequency::Weekly',
  biweekly: 'Subscriptions::Frequency::BiWeekly',
  monthly: 'Subscriptions::Frequency::Monthly',
};

// MessageCategory
export const ONBOARDING_TOUR = 'onboarding-tour';

// Submit
export const SUBMITTING_TEXT = 'Submitting...';

// Bundle
export const BUNDLE = 'Bundle';

// Protein Pack
export const PROTEIN_PACK = 'Protein Pack';

export const LUNCH = 'Lunch';

// MenuCategory
export const BUNDLE_AND_SAVE = 'bundle-and-save';
export const EXTRAS = 'extras';
export const LUNCH_CATEGORY = 'lunch';

// Partner Param
export const BLUEY = 'bluey';
export const PAW_PATROL = 'pawPatrol';
