import { decamelize } from 'helpers/camelize';

import {
  fetchWrapper,
  objectToQueryString,
  handleResponse,
  formatBody,
  buildOpts,
  hcUrl,
  transformBasketResponse,
  transformPastOrdersResponse,
  transformReceiptResponse,
  transformProjectedCosts,
  transformSkeletonBasketResponse,
} from '../helpers';

const getBasketSummaries = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl(`/weekly_baskets/summaries?${objectToQueryString(decamelize(params))}`),
    buildOpts()
  );
  return handleResponse(response);
};

const getBasket = async ({ params: { menuSlug } }) => {
  const response = await fetchWrapper(hcUrl(`/menus/${menuSlug}/weekly_basket`), buildOpts());
  return handleResponse(response, transformBasketResponse);
};

const getSkeletonBasket = async ({ params: { menuSlug } }) => {
  const response = await fetchWrapper(hcUrl(`/weekly_baskets/${menuSlug}/skeleton`), buildOpts());
  return handleResponse(response, transformSkeletonBasketResponse);
};

const editBasket = async ({ method, params }) => {
  const { menuSlug, ...restParams } = params;

  const response = await fetchWrapper(
    hcUrl(`/menus/${menuSlug}/weekly_basket`),
    buildOpts({
      method,
      body: formatBody(restParams),
    })
  );

  return handleResponse(response, transformBasketResponse);
};

const donateBasket = async ({ params }) => {
  const { menuSlug, servings } = params;

  const response = await fetchWrapper(
    hcUrl(`/menus/${menuSlug}/weekly_basket/donate`),
    buildOpts({
      method: 'PATCH',
      body: formatBody({ weeklyBasket: { servings } }),
    })
  );

  return handleResponse(response, transformBasketResponse);
};

const addBasket = ({ params }) => editBasket({ params, method: 'POST' });

const updateBasket = ({ params }) => editBasket({ params, method: 'PATCH' });

const updateBasketAddress = async ({ params }) => {
  const { menuSlug, address } = params;

  const response = await fetchWrapper(
    hcUrl(`/weekly_baskets/${menuSlug}/addresses`),
    buildOpts({
      method: 'PATCH',
      body: formatBody({ basketAddress: address, newAddress: false }),
    })
  );
  return handleResponse(response, transformBasketResponse);
};

const undonateBasket = async ({ params }) => {
  const { menuSlug } = params;

  const response = await fetchWrapper(
    hcUrl(`/menus/${menuSlug}/weekly_basket/cancel_donation`),
    buildOpts({
      method: 'PATCH',
    })
  );

  return handleResponse(response, transformBasketResponse);
};

const handleSkip = async ({ params, method }) => {
  const { menuSlug, ...restParams } = params;

  const response = await fetchWrapper(
    hcUrl(`/weekly_baskets/${menuSlug}/skips`),
    buildOpts({
      method,
      body: formatBody(restParams),
    })
  );

  return handleResponse(response, transformBasketResponse);
};

const skipBasket = ({ params }) => handleSkip({ params, method: 'POST' });
const unskipBasket = async ({ params }) => {
  return await handleSkip({ params, method: 'DELETE' });
};

const getTracking = async ({ params: { menuSlug } }) => {
  const response = await fetchWrapper(hcUrl(`/weekly_baskets/${menuSlug}/tracking`), buildOpts());

  return handleResponse(response);
};

const getReceipt = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl(`/weekly_basket/receipt?${objectToQueryString(decamelize(params))}`),
    buildOpts()
  );

  return handleResponse(response, transformReceiptResponse);
};

const getPastOrders = async ({ params: { page } }) => {
  const response = await fetchWrapper(
    hcUrl(`/weekly_baskets?page=${page}&per_page=10`),
    buildOpts()
  );

  return handleResponse(response, transformPastOrdersResponse);
};

const getProjectedCosts = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl(`/weekly_baskets/projected_costs?${objectToQueryString(decamelize(params, true))}`),
    buildOpts()
  );

  return handleResponse(response, transformProjectedCosts);
};

const getTieredPricingDetails = async ({ params }) => {
  const response = await fetchWrapper(hcUrl(`/meal_price_plans/${params.id}`), buildOpts());

  return handleResponse(response);
};

export {
  addBasket,
  donateBasket,
  getBasketSummaries,
  getReceipt,
  getBasket,
  getSkeletonBasket,
  getPastOrders,
  getTracking,
  getProjectedCosts,
  editBasket,
  skipBasket,
  updateBasket,
  updateBasketAddress,
  undonateBasket,
  unskipBasket,
  getTieredPricingDetails,
};
