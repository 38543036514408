import { combineReducers } from 'redux';

import mealAddons, * as fromMealAddons from './mealAddons';
import optIns, * as fromOptIns from './optIns';
import orderHistory, * as fromOrderHistory from './orderHistory';
import partnerDeals from './partnerDeals';
import pastReceipts, * as fromPastReceipts from './pastReceipts';
import pauseDeflectorMeals, * as fromPauseDeflectorMeals from './pauseDeflectorMeals';
import pauseReasons from './pauseReasons';
import streakOffers from './streakOffers';
import tasteProfile, * as fromTasteProfile from './tasteProfile';

export default combineReducers({
  orderHistory,
  mealAddons,
  optIns,
  tasteProfile,
  partnerDeals,
  pastReceipts,
  pauseReasons,
  pauseDeflectorMeals,
  streakOffers,
});

const selectAccount = state => state.account;

const selectOrderHistory = state => selectAccount(state).orderHistory;
const selectMealAddons = state => selectAccount(state).mealAddons;
const selectOptIns = state => selectAccount(state).optIns;
const selectTasteProfile = state => selectAccount(state).tasteProfile;
const selectPauseDeflectorMeals = state => selectAccount(state).pauseDeflectorMeals;
export const selectPauseReasonsIsLoading = state => selectAccount(state).pauseReasons.isLoading;
export const selectPauseReasons = state => selectAccount(state).pauseReasons.pauseReasons;
export const selectInlineDeflectors = state => selectAccount(state).pauseReasons.inlineDeflectors;
export const selectStreakOffers = state => selectAccount(state).streakOffers;
export const selectPartnerDeals = state => selectAccount(state).partnerDeals;
export const selectPastReceipts = state => selectAccount(state).pastReceipts;

export const selectPastReceipt = (state, menuSlug) =>
  fromPastReceipts.selectReceipt(selectPastReceipts(state), menuSlug);
export const selectPastReceiptLoading = state =>
  fromPastReceipts.selectReceiptLoading(selectPastReceipts(state));

export const selectOrderHistoryPastOrders = (state, page) =>
  fromOrderHistory.selectOrderHistoryPastOrders(selectOrderHistory(state), page);
export const selectOrderHistoryLoading = state =>
  fromOrderHistory.selectOrderHistoryLoading(selectOrderHistory(state));
export const selectOrderHistoryNumberOfPages = state =>
  fromOrderHistory.selectOrderHistoryNumberOfPages(selectOrderHistory(state));
export const selectOrderHistoryFailed = state =>
  fromOrderHistory.selectOrderHistoryFailed(selectOrderHistory(state));

export const selectMealAddonsResults = state =>
  fromMealAddons.selectMealAddonsResults(selectMealAddons(state));
export const selectMealAddonsLoading = state =>
  fromMealAddons.selectMealAddonsLoading(selectMealAddons(state));
export const selectMealAddonsFailed = state =>
  fromMealAddons.selectMealAddonsFailed(selectMealAddons(state));
export const selectMealAddonsUpdating = state =>
  fromMealAddons.selectMealAddonsUpdating(selectMealAddons(state));

export const selectOptInsResults = state => fromOptIns.selectOptInsResults(selectOptIns(state));
export const selectOptInsLoading = state => fromOptIns.selectOptInsLoading(selectOptIns(state));
export const selectOptInsFailed = state => fromOptIns.selectOptInsFailed(selectOptIns(state));
export const selectOptInsUpdating = state => fromOptIns.selectOptInsUpdating(selectOptIns(state));

export const selectPauseDeflectorMealsResults = state =>
  fromPauseDeflectorMeals.selectPauseDeflectorMealsResults(selectPauseDeflectorMeals(state));
export const selectPauseDeflectorMealsFetching = state =>
  fromPauseDeflectorMeals.selectPauseDeflectorMealsFetching(selectPauseDeflectorMeals(state));
export const selectPauseDeflectorMealsFailed = state =>
  fromPauseDeflectorMeals.selectPauseDeflectorMealsFailed(selectPauseDeflectorMeals(state));

export const selectTasteProfilePreferences = state =>
  fromTasteProfile.selectTasteProfilePreferences(selectTasteProfile(state));
export const selectTasteProfileAllergies = state =>
  fromTasteProfile.selectTasteProfileAllergies(selectTasteProfile(state));
export const selectTasteProfileLoading = state =>
  fromTasteProfile.selectTasteProfileLoading(selectTasteProfile(state));
export const selectTasteProfileError = state =>
  fromTasteProfile.selectTasteProfileError(selectTasteProfile(state));
export const selectTasteProfileUpdating = state =>
  fromTasteProfile.selectTasteProfileUpdating(selectTasteProfile(state));
