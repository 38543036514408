import * as fromAccount from 'reducers/account';
import { makeApiRequest } from 'actions/api';
import RequestService from 'services/RequestService';

export const ACCOUNT_PAST_ORDERS = 'ACCOUNT_PAST_ORDERS';
export const ACCOUNT_PAST_ORDERS_REQUEST = 'ACCOUNT_PAST_ORDERS_REQUEST';
export const ACCOUNT_PAST_ORDERS_RESPONSE = 'ACCOUNT_PAST_ORDERS_RESPONSE';
export const ACCOUNT_PAST_ORDERS_ERROR = 'ACCOUNT_PAST_ORDERS_ERROR';

export const getPastOrders = page => (dispatch, getState) => {
  const fetchedOrders = fromAccount.selectOrderHistoryPastOrders(getState(), page);
  if (fetchedOrders.length === 0) {
    dispatch(
      makeApiRequest({
        method: RequestService.getPastOrders,
        requestType: ACCOUNT_PAST_ORDERS,
        params: { page },
      })
    );
  }
};
